<template>
    <b-card class="procedure-card">
        <b-card-title class="card-title">
            <h2>{{procedure.type +' - '+ procedure.item.name}}</h2>

            <span>
                <v-edit
                    class="icon edit mr-1"
                    @click="$emit('edit-procedure')"
                />

                <v-delete
                    v-if="!edit"
                    @click="$emit('delete-procedure')"
                    class="icon delete"
                />

                <v-chevron-down class="chevron-icon" v-if="!showDetails" @click="showDetails = true"/>
                <v-chevron-up class="chevron-icon" v-else @click="showDetails = false"/>
            </span>
        </b-card-title>

        <b-row v-if="showDetails">
            <b-table-simple
                id="procedure-table"
                striped
                responsive
                class="mt-3 mx-auto"
                :key="reload"
            >
                <tbody>
                    <tr class="tr-header">
                        <th scope="col" width="25%">Dia da semana</th>
                        <th scope="col" width="65%">Períodos do dia</th>
                        <th scope="col" width="10%"></th>
                    </tr>

                    <tr v-for="(weekDay, index) of days" :key="index" class="tr-header">
                        <td width="20%"><span class="ml-2">{{getDayName(weekDay.weekDay)}}</span></td>
                        <td width="65%">
                            <multiselect
                                v-model="procedure.intervals[index]['intervals']"
                                placeholder="Selecionar"
                                :options="weekDay.intervals"
                                :close-on-select="false"
                                :option-height="40"
                                :showLabels="false"
                                :searchable="false"
                                :multiple="true"
                                @select="reloadTable"
                                @remove="reloadTable"
                                track-by="value"
                                label="label"
                                class="with-border multiple"
                                :disabled="!weekDay.active || !procedure.intervals[index].active || !weekDay.intervals.length"
                            >
                                <template slot="caret">
                                    <div class="chevron">
                                        <v-chevron-down />
                                    </div>
                                </template>

                                <template slot="selection" slot-scope="{ values }">
                                    <span v-if="values.length">{{values.length + (values.length > 1 ? ' itens' : ' item')}} selecionado{{(values.length > 1 ? 's' : '')}}</span>
                                </template>
                                <template slot="noOptions"> Nenhuma opção </template>
                                <template slot="noResult"> Nenhum resultado </template>
                            </multiselect>
                        </td>
                        <td width="15%">
                            <div v-if="weekDay.active && weekDay.intervals.length" class="d-flex">
                                <label class="toggle-subtitle" v-if="procedure.intervals[index].active">Ativo</label>
                                <label class="toggle-subtitle" v-else>Inativo</label>
                                <toggle-button
                                    class="ml-1"
                                    :width="40"
                                    :height="24"
                                    :value="Boolean(procedure.intervals[index].active)"
                                    @change="changeActiveInterval(index)"
                                    :color="{ checked: '#00C773' }"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </b-row>
    </b-card>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ChevronUp from '@/assets/icons/chevron-up.svg'
import Delete from '@/assets/icons/delete.svg'
import Edit from '@/assets/icons/edit.svg'

export default {
    name: 'ProcedureCard',
    props: {
        procedure: Object,
        validated: Boolean,
        days: Array,
        edit: Boolean,
    },
    components: {
        'v-chevron-down': ChevronDown,
        'v-chevron-up': ChevronUp,
        'v-delete': Delete,
        'v-edit': Edit
    },
    data() {
        return {
            showDetails: true,
            reload: 0,
        }
    },
    methods: {
        getDayName(index){
            switch (index){
                case 0:
                    return 'Domingo'
                case 1:
                    return 'Segunda'
                case 2:
                    return 'Terça'
                case 3:
                    return 'Quarta'
                case 4:
                    return 'Quinta'
                case 5:
                    return 'Sexta'
                case 6:
                    return 'Sábado'
            }
        },

        changeActiveInterval(index){
            this.procedure.intervals[index].active = !this.procedure.intervals[index].active
            this.reloadTable()
        },

        reloadTable(){
            if(this.edit) this.reload++
        }
    }
}
</script>

<style lang="scss" scoped>
    .procedure-card {
        border: 1px solid #C6CEEB;
        border-radius: 8px;
    }

    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        h2 {
            padding-top: 10px;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #0C1D59;
        }
    }

    .icon {
        width: 20px;
        height: 24px;
        cursor: pointer;

        &.edit {
        stroke: var(--blue-500);
        }

        &.delete {
            stroke: var(--red-500);
        }

        :hover{
            color:gray;
        }
    }

    .chevron-icon {
        width: 28px;
        height: 28px;
        cursor: pointer;

        :hover{
            color:gray;
        }
    }

    #procedure-table {
        .table th, .table td {
            vertical-align: middle;
            border-top: 1px dashed var(--neutral-200);
            font-size: 13px;
        }

        .icon {
            width: 20px;
            height: 24px;
            cursor: pointer;

            &.edit {
            stroke: var(--blue-500);
            }

            &.delete {
            stroke: var(--red-500);
            }
        }

        .tr-header {
            border: none;
            th:first-child {
                padding-left: 10px;
            }

            .table th, .table td {
                padding: 0;
                vertical-align: middle;
                border-top: none;
            }

            td:first-child {
                padding: 16px 2px 16px 2px !important;
                max-width: 25ch;
            }
        }

        th {
            font-size: 14px;
            color: #0C1D59;
        }

        td {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #525C7A;
            padding: 0.75rem !important;
        }
    }
</style>
